<template>
  <el-container>
    <el-main>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="门店名称：" prop="storename">
          <el-input v-model="form.storename" class="nomalwidth"></el-input>
        </el-form-item>
        <el-form-item label="门店简介：" prop="storename">
          <el-input v-model="form.store_intro" class="nomalwidth"></el-input>
        </el-form-item>
        <el-form-item label="门店logo：" prop="storelogo">
          <ReadyUploadSource @getSource="getLogoImg" :path="form.storelogo" @removeThis="() => (form.storelogo = '')"></ReadyUploadSource>
          <div class="formdiv">图片尺寸120*120，大小不超过200KB</div>
        </el-form-item>
        <el-form-item label="门店banner：" prop="storebanner">
          <ReadyUploadSource @getSource="getBnnerImg" @changeOrder="changeOrder" @removeThis="removeImageThis" :isMany="true" :manyPath="form.storebanner" :isManyMax="10"></ReadyUploadSource>
          <div class="formdiv">建议图片尺寸530*180px，大小不超过200KB</div>
        </el-form-item>
        <el-form-item label="门店电话：" prop="storetel">
          <el-input v-model="form.storetel" class="nomalwidth"></el-input>
        </el-form-item>
        <el-form-item label="门店位置：" required>
          <div style="display: flex">
            <el-form-item prop="storelongitude" style="margin-right: 50px">
              <el-input disabled v-model="form.storelongitude" placeholder="地理经度" class="halfwidth"></el-input>
            </el-form-item>
            <el-form-item prop="storelatitude" style="margin-right: 40px">
              <el-input disabled v-model="form.storelatitude" placeholder="地理纬度" class="halfwidth"></el-input>
            </el-form-item>
            <!-- <el-link type="primary" :underline="false" style="white-space: nowrap">选择坐标</el-link> -->
            <el-button type="primary" @click="dialogMap = true">定位</el-button>
            <el-dialog title="选择坐标" :visible.sync="dialogMap">
              <Map ref="map" :lng="form.storelongitude" :lat="form.storelatitude"></Map>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogMap = false">取 消</el-button>
                <el-button type="primary" @click="confirmCoordinate">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="门店详细地址：" prop="storeaddress">
          <el-input disabled v-model="form.storeaddress" class="nomalwidth"></el-input>
        </el-form-item>

        <el-form-item label="营业时间：" required>
          <div style="display: flex; margin-bottom: 20px" v-for="(item, index) in form.dotime" :key="index">
            <el-form-item style="margin-right: 50px" :error="item.isShowStartError ? '请输入开始时间' : false" :show-message="item.isShowStartError">
              <el-time-picker v-model="item.dotimestart" placeholder="开始时间" class="halfwidth" @change="validateDotimestart(item)" value-format="HH:mm:ss"></el-time-picker>
            </el-form-item>
            <el-form-item style="margin-right: 50px" :error="item.isShowEndError ? '请输入结束时间' : false" :show-message="item.isShowEndError">
              <el-time-picker v-model="item.dotimeend" placeholder="结束时间" class="halfwidth" @change="validateDotimeend(item)" value-format="HH:mm:ss"></el-time-picker>
            </el-form-item>
          </div>
          <div>
            <el-link type="primary" :underline="false" icon="el-icon-plus" @click="addDotime" style="line-height: 20px" v-if="form.dotime.length < 3">添加营业时间</el-link>
          </div>
        </el-form-item>
        <el-form-item label="是否支持自提：">
          <el-switch v-model="form.isselftake"></el-switch>
        </el-form-item>
        <el-form-item label="是否营业：">
          <el-switch v-model="form.isdo"></el-switch>
        </el-form-item>
        <el-form-item label="利润抽成：">
          <el-input disabled oninput="value=value.replace(/[^\d]/g,'')" placeholder="此项只能填写数字" v-model.number="form.profit" class="nomalwidth"></el-input>
          %
        </el-form-item>
        <Preservation @preservation="saveForm"></Preservation>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import Map from './components/Map';
import { inspectPhone } from '@/util/verification';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Map,
    ReadyUploadSource,
    Preservation,
  },
  data: function () {
    return {
      imgListDialog: false,
      dialogVisible: false,
      indexItem: -1,
      form: {
        storename: null,
        storelogo: null,
        storebanner: [],
        storetel: null,
        store_intro: null,
        storeaddress: null,
        storelongitude: null,
        storelatitude: null,
        dotime: [
          {
            dotimestart: null,
            dotimeend: null,
            isShowStartError: false,
            isShowEndError: false,
          },
        ],
        isselftake: false,
        isdo: false,
        profit: null,
      },
      rules: {
        storename: [{ required: true, message: '请输入门店名称', trigger: 'blur' }],
        storelogo: [{ required: true, message: '请输入门店logo', trigger: 'blur' }],
        storebanner: [{ required: true, message: '请输入门店banner', trigger: 'blur' }],
        storetel: [
          { required: true, message: '请输入门店电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!inspectPhone(value)) {
                callback(new Error('请输入电话正确格式'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        storeaddress: [{ required: true, message: '请输入门店地址', trigger: 'blur' }],
        storelongitude: [{ required: true, message: '请输入地理经度', trigger: 'blur' }],
        storelatitude: [{ required: true, message: '请输入地理纬度', trigger: 'blur' }],
      },
      dialogMap: false,
    };
  },
  created() {
    this.$axios.post(this.$api.user.shopInfo).then((res) => {
      if (res.code == 0) {
        let obj = res.result;
        let dotime = [];
        for (let v of obj.open_times) {
          dotime.push({
            dotimestart: v.start_time,
            dotimeend: v.end_time,
            isShowStartError: false,
            isShowEndError: false,
          });
        }
        this.logoPath = obj.store_logo;
        this.form = {
          id: obj.id,
          storename: obj.store_name,
          storelogo: obj.store_logo,
          storebanner: obj.store_banners,
          store_intro: obj.store_intro,
          storetel: obj.store_phone,
          storeaddress: obj.store_site,
          storelongitude: obj.store_longitude,
          storelatitude: obj.store_latitude,
          dotime: dotime,
          isselftake: obj.is_take_their === 1,
          isdo: obj.is_open === 1,
          profit: obj.rake,
        };
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  methods: {
    //获取服务图片
    getBnnerImg(val) {
      let arr = [...this.form.storebanner];
      for (let i in val) arr.push(val[i].path);
      this.form.storebanner = arr;
    },
    // 拖动重新赋值数据
    changeOrder(val) {
      this.form.storebanner = val;
    },
    // 删除单张图片
    removeImageThis(index) {
      this.form.storebanner.splice(index, 1);
    },
    getLogoImg(val) {
      this.form.storelogo = val.path;
    },
    saveForm() {
      this.$refs['form'].validate((valid) => {
        let flag = false;
        for (let i of this.form.dotime) {
          if (!i.dotimestart) {
            flag = true;
            i.isShowStartError = true;
          }
          if (!i.dotimeend) {
            flag = true;
            i.isShowEndError = true;
          }
        }
        if (valid && !flag) {
          let form = this.form;
          let open_time = [];
          for (let v of form.dotime) {
            open_time.push({
              start_time: v.dotimestart,
              end_time: v.dotimeend,
            });
          }
          if (!Number(form.profit)) {
            this.$message({
              message: '利润抽成只能填写数值',
              type: 'warning',
            });
            return;
          } else {
            if (Number(form.profit) < 0 || Number(form.profit) > 1) {
              this.$message({
                message: '利润抽成区间在[0,1]之间',
                type: 'warning',
              });
              return;
            }
          }
          let data = {
            store_name: form.storename,
            store_logo: form.storelogo,
            store_banner: form.storebanner,
            store_intro: form.store_intro,
            store_phone: form.storetel,
            store_site: form.storeaddress,
            store_longitude: form.storelongitude,
            store_latitude: form.storelatitude,
            is_take_their: form.isselftake ? 1 : 0,
            is_open: form.isdo ? 1 : 0,
            rake: form.profit,
            open_time: open_time,
          };
          if (form.id) data.id = form.id;
          this.$axios.post(this.$api.store.edit, data).then((res) => {
            if (res.code == 0) {
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    addDotime() {
      if (this.form.dotime.length < 3) {
        this.form.dotime.push({
          dotimestart: null,
          dotimeend: null,
          isShowStartError: false,
          isShowEndError: false,
        });
      }
    },
    validateDotimestart(item) {
      item.isShowStartError = !item.dotimestart;
    },
    validateDotimeend(item) {
      item.isShowEndError = !item.dotimeend;
    },
    confirmCoordinate() {
      let coordinate = this.$refs.map.currentCoordinate;
      let obj = this.$refs.map.address;
      console.log(obj);
      this.form.storeaddress = obj.province + obj.city + obj.district + obj.street + obj.streetNumber + this.$refs.map.search;
      this.form.storelongitude = coordinate.lng;
      this.form.storelatitude = coordinate.lat;
      this.dialogMap = false;
    },
    changeStatus(val) {
      this.dialogVisible = val;
    },
  },
  watch: {
    dialogVisible(val) {
      this.imgListDialog = val;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background: #fff;
  padding-bottom: 50px;
}
.formlogo {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
}
.logodiv {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    font-size: 14px;
    color: #9a9a9a;
    margin-left: 3px;
  }
}

.formdiv {
  color: #9a9a9a;
}
.nomalwidth {
  width: 780px;
}
.halfwidth {
  width: 365px;
}
/deep/ .el-dialog__wrapper {
  display: flex;
  align-items: center;
  .el-dialog {
    margin: 0 auto !important;
  }
  .el-dialog__header {
    background-color: #f0f0f0;
  }
}

</style>
